html {
    height: 100%;
}
body {
    height: 100%;
    overflow-y: hidden;
} 

#root {
   height: 100%;
}

/* body {
    overflow-y: hidden;
    overflow-x: hidden;
} */

/* width */
::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #263238;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    /* background: #263238; */
    background: #263238;
}